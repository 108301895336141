.services-map-block {
  background-color: var(--background-color);
  color: var(--font-color2);
  padding: 6rem 0;
}

.item {
  padding: 1rem;
}

.item ul {
  list-style: none;
  text-align: left;
}
.light-blue {
  background-color: var(--accent-color);
  border-radius: 5px;
}

.dark-blue {
  background-color: var(--background-color);
}

@media (max-width: 700px) {
  .services-map-block {
    padding: 6rem 1rem;
  }

  .item {
    padding:0.5rem;
    margin: 1rem 0;
  }

  .item ul {
    padding-left: 0.5rem;
  }

  .before-last {
    background-color: var(--accent-color); /* Change to dark blue color */
  }
  .last {
    background-color: var(--background-color); /* Change to light blue color */
  }
}
