.services-block {
  background-color: var(--background-color);
  color: var(--font-color2);
  padding-top: 10rem;
}

.title-holder {
  text-align: center;
}
.title-holder h1{
  color: var(--font-color1);
}
.cards-container {
  display: flex;
  padding: 2rem 6rem 6rem;
  justify-content: space-around;
}

.service-card {
  height: 100%;
}

.card-img {
  height: 200px; /* Set the desired height for the images */
  object-fit: cover; /* Ensure the image covers the entire container */
}

.card {
  padding: 0.3rem;
  background-color: var(--light-background-color);
  color: var(--font-color2);
  border: 1px solid var(--font-color);
}

.card-title {
  text-align: center;
  padding-bottom: 1rem;
}

.services-map-block {
  background-color: var(--background-color
  );
}

.section-title {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .cards-container {
    display: flex;
    padding: 1rem;
    justify-content: space-around;
    flex-direction: column;
  }

}
