.hero-container {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  overflow: hidden;

}

.hero-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  opacity: 0.5;
  border-bottom: 1px solid var(--background-color);
}

.hero-text-block {
  position: absolute;
  top: 30%;
  left: 10%;
  text-align: left;
  color: var(--font-color2);
  z-index: 1;
  max-width: 40vw;
}

.title {
  font-weight: 600;
  font-size: xxx-large;
}

.subtitle {
  font-size: xx-large;
  margin-top: 2rem;
  font-weight: 100;
}

.extra-info {
  font-size: x-large;
  margin-top: 1rem;
  font-weight: 100;
}

.hero-text-block .btn {
  margin-top: 4rem;
}

@media only screen and (max-width: 767px) {
  .hero-container {
    position: initial;
    overflow: hidden;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-text-block {
    max-width: 90vw;
    top: 40%;
    left: unset;
  }
  .title {
    font-size: xx-large;
  }

  .subtitle {
    font-size: x-large;
  }

  .extra-info {
    font-size: large;
    margin-top: 1rem;
    font-weight: 100;
  }
}
