#info-block {
  color: var(--font-color2);
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-background-color);
}

.col-lg-4 {
  padding: 0 2rem;
}

.info-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.text-holder {
  background-color: var(--background-color);
  padding: 1rem;
  border-radius: 5px;
}

#info-block p{
  text-align: justify;
}


@media only screen and (max-width: 767px) {
  #info-block {
    margin: 2rem 0
  }

  .col-lg-4 {
    margin-bottom: 1rem;
  }

}
