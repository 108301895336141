#contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 6rem 0;
  background-color: var(--light-background-color);
}

#contact h1 {
  color: var(--font-color2);
}
.contact-title{
  padding-bottom: 2rem;
}

.second-title {
  padding: 2rem 0;
}
.contact-subtitle {
  padding: calc(var(--bs-gutter-x)* .5)
}

input[type=text],
input[type=email],
input[type=tel],
input[type=search],
textarea {
  padding: 5px 10px;
  font-size: 15px;
}

.message-textarea {
  height: 80%;
}


.form-label, .contact-subtitle h2 {
  color: var(--font-color)
}
.contact-block {
  position: relative;
}

.contact-block .row {
  margin: 0 0 25px;
}

.contact-block .btn-holder {
  text-align: center;
}

.contact-form {
  margin: 0 0 60px;
  padding: 2rem;
  border: 1px solid var(--font-color);
  border-radius: 5px;
}

.call-block{
  text-align: center;
}

.call-block h1{
  padding-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
  input[type=text],
  input[type=email] {
    margin-bottom: 1rem;
  }

  .contact-form {
    margin: 2rem 1rem;
    padding: 1rem;
  }
}
