:root {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  --background-color: #112e42;
  --light-background-color: #153b55;
  --font-color: #77D4FC ;
  --font-color2: #ededed;
  --accent-color: #4B91F1;
}

html, main {
  background-color: var(--background-color);
}

a.nav-link {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: larger;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

h1 {
  font-family: 'nunito', sans-serif;

}

h2, h3, h4, p {
  font-family: 'roboto', sans-serif;
}

p {
  font-weight: 100;
}

@media (max-width: 700px) {

}
